export default [
  {
    label: 'Profile',
  },
  {
    label: 'Workouts',
  },
  {
    label: 'Actions',
    meta: 'User',
  },
];
