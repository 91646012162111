import { combineReducers } from 'redux';
import appReducer from './app/reducer';
import userReducer from './user/reducer';
import adminReducer from './admin/reducer';
import docReducer from './doc/reducer';
import exerciseReducer from './exercise/reducer';
import programmeReducer from './programme/reducer';
import recoveryReducer from './recovery/reducer';
import equipmentReducer from './equipment/reducer';
import optionReducer from './option/reducer';

const app = appReducer;
const options = optionReducer;

const users = userReducer;
const admins = adminReducer;
const docs = docReducer;
const recovery = recoveryReducer;
const programmes = programmeReducer;
const exercises = exerciseReducer;
const equipments = equipmentReducer;

export default combineReducers({
  app,
  options,

  users,
  admins,

  equipments,
  exercises,
  programmes,
  recovery,
  docs,
});
