import React from 'react';
import AppLink from './AppLink';
import appConfig from '../appConfig';

export default props => (
  <nav>
    <ul className="nav flex-column">
      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          exact
          to="/dashboard"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-tachometer-alt"></i> Dashboard
        </AppLink>
      )}

      {/* {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/users"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i class="fas fa-running"></i> Athletes
        </AppLink>
      )} */}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/equipments"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-dumbbell"></i> Equipments
        </AppLink>
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/exercises"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-hockey-puck"></i> Exercises
        </AppLink>
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/programmes"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-tasks"></i> Programmes
        </AppLink>
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/recovery"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-stethoscope"></i>Rehab
        </AppLink>
      )}

      {(props.loggedInUser.roles.includes(appConfig.role[0]) ||
        props.loggedInUser.roles.includes(appConfig.role[1])) && (
        <AppLink
          to="/docs"
          className="nav-link"
          isConfirmationModalEnabled={props.isSidebarConfirmationEnabled}
        >
          <i className="fas fa-file"></i> Docs
        </AppLink>
      )}

      {props.children}
    </ul>
  </nav>
);
