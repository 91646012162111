import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import baseModel from '../../ressources/user/baseModel';
import newModel from '../../ressources/user/newModel';
import * as Actions from '../../redux/user/actions';
import Editor from './Editor';

const mapStateToProps = state => {
  return { items: state.users.collection };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchChunk: start => dispatch(Actions.fetchChunk(start)),
    updItem: (year, item) => dispatch(Actions.updItem(year, item)),
    delItem: id => dispatch(Actions.delItem(id)),
  };
};

class Single extends Component {
  static defaultProps = {
    items: [],
  };
  constructor(props) {
    super(props);
    this.fetch();
  }
  fetch() {
    this.props.fetchChunk();
  }
  getItemInEdition() {
    const { items, match } = this.props;
    const { _id } = match.params;
    const id = _id;

    let base;
    if (id === 'add') {
      base = newModel;
    } else {
      base = baseModel;
    }

    // ITEM
    console.log(items);
    const item = items.filter(item => item._id === _id)[0] || base;
    console.log(item, 'item');
    return item;
  }
  render() {
    const item = this.getItemInEdition();
    const { match, readOnlyMode, deleteMode, isDuplicata } = this.props;

    if (isDuplicata) {
      item._id = 'new';
    }

    let slugLevels = match.url.split('/');
    slugLevels.pop();
    if (
      slugLevels.slice(-1) == 'edit' ||
      slugLevels.slice(-1) == 'delete' ||
      slugLevels.slice(-1) == 'duplicate'
    ) {
      slugLevels.pop();
    }
    const back = slugLevels.join('/');

    return (
      <>
        {item && (
          <Editor
            item={item}
            back={back}
            readOnlyMode={readOnlyMode}
            deleteMode={deleteMode}
          />
        )}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Single));
