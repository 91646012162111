import appConfig from '../../appConfig';
import ActionTypes from './actionTypes';
import axios from '../../axiosConfig';
import * as AppActions from '../app/actions';
const API_URL = appConfig.api_url;
const ENTITY_URL = 'user-profiles';
const ENTITY = 'User';

export const fetchChunk = (start, limit, textSearch) => dispatch => {
  dispatch(fetchChunkBegin(start, limit));
  let queryParams = [];
  if (start > 0) {
    queryParams.push(`start=${start}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (!_.isEmpty(textSearch)) {
    queryParams.push(`textSearch=${textSearch}`);
  }
  axios
    .get(`${API_URL}/${ENTITY_URL}?${queryParams.join('&')}`)
    .then(response => {
      let items = response.data.data;
      let count = response.data.count;
      setTimeout(() => {
        dispatch(fetchChunkSuccess(items, count, start));
      }, 400);
    })
    .catch(error => dispatch(fetchChunkError(error)));
};
export const fetchChunkBegin = (start, limit) => ({
  type: ActionTypes.FETCH_CHUNK_BEGIN,
  payload: null,
});
export const fetchChunkSuccess = (items, count, start) => ({
  type: ActionTypes.FETCH_CHUNK_SUCCESS,
  payload: items,
  count: count,
  meta: {
    start,
  },
});
export const fetchChunkError = error => ({
  type: ActionTypes.FETCH_CHUNK_ERROR,
  payload: error,
});

export const clearCollection = error => ({
  type: ActionTypes.CLEAR_COLLECTION,
});

export const updItem = item => dispatch => {
  // Create data as a FormData from the event object
  // const data = utils.toFormData(item);
  const data = item;

  let method = '';
  let idParam = '';
  let actionMessageVerb;
  if (item._id === 'new') {
    method = 'post';
    idParam = '';
    actionMessageVerb = 'created';
  } else {
    method = 'put';
    idParam = item._id;
    actionMessageVerb = 'updated';
  }

  axios({
    method: method,
    url: `${API_URL}/${ENTITY_URL}/${idParam}`,
    data,
  })
    .then(response => {
      let data = response.data.data;
      dispatch(
        AppActions.messageSuccess(
          `${ENTITY} successfully ${actionMessageVerb}`,
        ),
      );
      dispatch({ type: ActionTypes.UPD_ITEM, payload: data });
    })
    .catch(error => {
      console.log('ERROR');
      console.log(error);
      dispatch(
        AppActions.messageError(
          'Oops Something goes wrong. Please retry in a few seconds',
        ),
      );
    });
};

export const delItem = id => dispatch => {
  axios({
    method: 'delete',
    url: `${API_URL}/${ENTITY_URL}/${id}`,
  })
    .then(response => {
      dispatch({ type: ActionTypes.DEL_ITEM, payload: id });
    })
    .catch(error => {
      console.log(error);
    });
};

export const setTablePage = page => ({
  type: ActionTypes.SET_TABLE_PAGE,
  payload: page,
});
