import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class StatResume extends Component {
  static defaultProps = {
    stats: [],
    value: '--',
  };
  render() {
    const { label, value, stats, icon, linkTo } = this.props;
    let { className } = this.props;
    className = className ? ' ' + className : '';

    const content = (
      <div className={'widget stat-resume' + className}>
        <div className="icon-wrapper">{icon}</div>
        <h3>{label}</h3>
        <Row className="">
          <Col xs={12} className="flex-column">
            <div className="lead">{value || '--'}</div>
          </Col>

          {stats && stats.length > 0 && (
            <Col xs={7} className="flex-column">
              <ul className="list-unstyled my-auto">
                {stats.map((el, i) => (
                  <li key={i}>
                    <span>{el}</span>
                  </li>
                ))}
              </ul>
            </Col>
          )}
        </Row>
      </div>
    );

    return (
      <Col lg="4" md="4" sm="12">
        {linkTo && <Link to={linkTo}>{content}</Link>}
        {!linkTo && <>{content}</>}
      </Col>
    );
  }
}

export default StatResume;
